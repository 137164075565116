* {
    box-sizing: border-box;
}

body {
    width: 100%;
    margin: 0;
}

#bulk_reply .ql-editor {
    min-height: 100px;
}

#modal-bulk .ql-container {
    max-height: 100px;
    min-height: 100px;

    .ql-editor {
        min-height: inherit;
        max-height: inherit;
        padding: 0;
        padding-right: 1vw;
    }
}

.form-layout-6 .row+.row>div {
    border-top-width: 1px;
}

@import 'ticket';
.ticket-cont {
    background: #fff;
    color: #000;
    overflow: hidden;
    filter: drop-shadow(0 5px 3px rgba(0,0,0,.1));
    margin-left: 15px;
    margin-right: 15px;

    .number_stats {
        * {
            margin: 0;
            text-align: center;
            padding: 0 2vw;
        }

        p {
            &:nth-of-type(2) {
                color: white;
            }
        }
    }

    .problem-cont {
        .title, .snippet {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .last-updated {
        * {
            margin: 0;
        }
    }

    .bottom-panel {
        width: 100%;
    }

    .view-detail {
        align-self: center;
        text-align: center;
    }

    .detail {
        * {
            margin: 0
        }
        align-self: stretch;
    }
}

.tab-cont {
    background: #fff;
    margin: 0 30%;
    .tab-item {
        flex-basis: calc(100%/4);
        text-align: center;
        border: 1px solid #000;
    }
}

// .attach-cont {
//     a:hover {
//         background: #1b84e7;
//         color: #fff;
//     }
// }


// @import 'ticket/all'